






import Vue from 'vue';
import AppView from '@/assets/AppView';

import BaseWrapper from '@/components/BaseWrapper.vue';
import ContentCard from '@/components/ContentCard.vue';

export default Vue.extend({
  name: AppView.Samples,

  components: {
    BaseWrapper,
    ContentCard,
  },

  metaInfo() {
    return {
      title: this.$t('view.samples') as string,
    };
  },

  data() {
    return {
      samples: [
        {
          slug: 'elevator',
          name: this.$t('sample.elevator.name'),
          desc: this.$t('sample.elevator.desc'),
          links: [
            {
              name: this.$t('link.demo'),
              url: 'https://elevator.elushnikova.dev/',
            },
            {
              name: this.$t('link.source'),
              url: 'https://github.com/elushnikova/elevator',
            },
          ],
        },
        {
          slug: 'timer',
          name: this.$t('sample.timer.name'),
          desc: this.$t('sample.timer.desc'),
          links: [
            {
              name: this.$t('link.demo'),
              url: 'https://timer.elushnikova.dev/',
            },
            {
              name: this.$t('link.source'),
              url: 'https://github.com/elushnikova/timer',
            },
          ],
        },
        {
          slug: 'alphyn',
          name: this.$t('sample.alphyn.name'),
          desc: this.$t('sample.alphyn.desc'),
          links: [
            {
              name: this.$t('link.demo'),
              url: 'https://alphyn.team/',
            },
            {
              name: this.$t('link.source'),
              url: 'https://github.com/elushnikova/alphyn',
            },
          ],
        },
        {
          slug: 'my-site',
          name: this.$t('sample.site.name'),
          desc: this.$t('sample.site.desc'),
          links: [
            {
              name: this.$t('link.source'),
              url: 'https://github.com/elushnikova/my-site',
            },
          ],
        },
        {
          slug: 'weather-widget',
          name: this.$t('sample.weather.name'),
          desc: this.$t('sample.weather.desc'),
          links: [
            {
              name: this.$t('link.demo'),
              url: 'https://weather.elushnikova.dev/demo',
            },
            {
              name: this.$t('link.source'),
              url: 'https://github.com/elushnikova/weather-widget',
            },
          ],
        },
        {
          slug: 'hour-rules',
          name: this.$t('sample.hours.name'),
          desc: this.$t('sample.hours.desc'),
          links: [
            {
              name: this.$t('link.demo'),
              url: 'https://hours.elushnikova.dev/',
            },
            {
              name: this.$t('link.source'),
              url: 'https://github.com/elushnikova/hour-rules',
            },
          ],
        },
      ],
    };
  },
});
