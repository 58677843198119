





















import Vue from 'vue';

import BaseCard from '@/components/BaseCard.vue';
import BaseLink from '@/components/BaseLink.vue';
import NavList from '@/components/NavList.vue';
import NavListItem from '@/components/NavListItem.vue';

export default Vue.extend({
  name: 'ContentCard',

  components: {
    BaseCard,
    BaseLink,
    NavList,
    NavListItem,
  },

  props: {
    item: {
      type: Object,
      required: true,
      validator(value) {
        const hasName = typeof value.name === 'string';
        const hasDesc = typeof value.desc === 'string';
        return hasName && hasDesc;
      },
    },
    wrapLinks: { type: Boolean, default: false },
  },
});
