








import Vue from 'vue';

import BaseTransition from '@/components/BaseTransition.vue';

export default Vue.extend({
  name: 'BaseWrapper',

  components: {
    BaseTransition,
  },
});
